<template>
  <div id="stageline-page-component">
    <router-view />
  </div>
</template>

<script>

export default {
  name: 'StagelinePage',
}
</script>
<style scoped lang="scss">
  #stageline-page-component {
    width: 100%;
  }

  #stageline-page-component > div {
    display: flex;
    flex-direction: row;
  }

  @media only screen and (max-width: 1024px) {
    #stageline-page-component {
      width: 100% !important;
    }
  }
</style>
